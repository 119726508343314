import React from "react";
import "./Footer.css";

import ReactCountryFlag from "react-country-flag";

import { FaPhoneSquare, FaLocationArrow, FaFacebookF } from "react-icons/fa";

import { AiFillInstagram, AiOutlineMail } from "react-icons/ai";

const countryCodeList = [
  "AR",
  "BO",
  "CL",
  "CO",
  "CR",
  "DO",
  "EC",
  "SV",
  "HN",
  "GT",
  "NI",
  "MX",
  "PY",
  "PA",
  "PE",
  "PR",
  "US",
  "UY",
  "VE",
];

function Footer() {
  return (
    <section className="footer-index">
      <div className="container py-3">
        <div className="mid mb-5 mt-3">
          <h2 className="main-head-text text-white text-center">
            Números de contacto por país
          </h2>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <ul className="list-unstyled">
              <li>
                <a href="tel:+54-11-598-44025">
                  Argentina:&nbsp;&nbsp;+54-11-598-44025
                </a>
              </li>
              <li>
                <a href="tel:+506-410-53035">
                  Costa Rica:&nbsp;&nbsp;+506-410-53035
                </a>
              </li>
              <li>
                <a href="tel:+504-2217-0377">
                  Honduras:&nbsp;&nbsp;+504-2217-0377
                </a>
              </li>
              <li>
                <a href="tel:+1-786-706-5249">
                  Paraguay:&nbsp;&nbsp;+1-786-706-5249
                </a>
              </li>
              <li>
                <a href="tel:+17867065249">
                  Estados Unidos:&nbsp;&nbsp;1 786-706-5249
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="list-unstyled">
              <li>
                <a href="tel:+1-786-706-5249">
                  Bolivia:&nbsp;&nbsp;+1-786-706-5249
                </a>
              </li>
              <li>
                <a href="tel:+1-829-946-3769">
                  Rep. domini:&nbsp;&nbsp;+1-829-946-3769
                </a>
              </li>
              <li>
                <a href="tel:+502-2458-1819">
                  Guatemala:&nbsp;&nbsp;+502-2458-1819
                </a>
              </li>
              <li>
                <a href="tel:+507-832-7606">Panamá:&nbsp;&nbsp;+507-832-7606</a>
              </li>
              <li>
                <a href="tel:+041 359-83171">
                  Uruguay:&nbsp;&nbsp;+041 359-83171
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="list-unstyled">
              <li>
                <a href="tel:+56-232104775">Chile:&nbsp;&nbsp;+56-232104775</a>
              </li>
              <li>
                <a href="tel:+1-786-7065249">
                  Ecuador:&nbsp;&nbsp;+1-786-7065249
                </a>
              </li>
              <li>
                <a href="tel:+1-786-706-5249">
                  Nicaragua:&nbsp;&nbsp;+1-786-706-5249
                </a>
              </li>
              <li>
                <a href="tel:+51-1705-8267">Perú:&nbsp;&nbsp;+51-1705-8267</a>
              </li>
              <li>
                <a href="tel:+1-786-706-5249">
                  Venezuela:&nbsp;&nbsp;+1-786-706-5249
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <ul className="list-unstyled">
              <li>
                <a href="tel:+57-60-1-841-8626">
                  Colombia:&nbsp;&nbsp;+57-60-1-841-8626
                </a>
              </li>
              <li>
                <a href="tel:+503-213-61298">
                  El Salvador:&nbsp;&nbsp;+503-213-61298
                </a>
              </li>
              <li>
                <a href="tel:+52-554-631-9967">
                  México:&nbsp;&nbsp;+52-554-631-9967
                </a>
              </li>
              <li>
                <a href="tel:+1-786-706-5249">
                  Puerto Rico:&nbsp;&nbsp;+1-786-706-5249
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr style={{ borderTop: "1px solid #FFFFFF" }} />
        <div className="row justify-content-md-center">
          <div className="col-sm-4">
            {/* <h5>Contact us</h5> */}
            <ul className="list-unstyled">
              <li>
                <a
                  href="https://www.google.lk/maps/place/Orlando+Office+Center+at+Millenia/@28.4846797,-81.4423311,17z/data=!3m1!4b1!4m5!3m4!1s0x88e77ebda375a717:0x5cccde54e1be6e6d!8m2!3d28.4846797!4d-81.4401424"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLocationArrow />
                  &nbsp;Millenia Boulevard Office Center, <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4700 Millenia Boulevard Suite
                  175,
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Orlando, FL 32839
                </a>
              </li>
              <li>
                <a
                  href="https://www.google.lk/maps/place/7,+77503+Canc%C3%BAn,+Quintana+Roo,+Mexico/@21.1402433,-86.834079,15z/data=!3m1!4b1!4m5!3m4!1s0x8f4c2be16d1dea23:0xfbd00fc06b7bd244!8m2!3d21.1452834!4d-86.8226617"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLocationArrow />
                  &nbsp;Av. Sayil esquina con Av. Savigna <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Malecón Tajamar Piso 11,
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cancun, 77503
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            {/* <h5>Contact us</h5> */}
            <ul className="list-unstyled">
              <li>
                <a href="tel:+17867065249">
                  <FaPhoneSquare />
                  &nbsp; 1 786-706-5249
                </a>
              </li>
              <li>
                <a href="mailto:info@vacationcoupon.com?cc=other@domain.com">
                  <AiOutlineMail />
                  &nbsp; info@vacationcoupon.com
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <div className="center">
              {/* <h5>Follow Us</h5> */}
              <ul className="social-network social-circle">
                <li>
                  <a
                    href="https://www.facebook.com/vacationcoupon"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                    title="Facebook"
                  >
                    <FaFacebookF style={{ color: "#237db2" }} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/vacationcoupon/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icoInstagram"
                    title="Instagram"
                  >
                    <AiFillInstagram
                      style={{ color: "#237db2", fontSize: "25px" }}
                    />
                  </a>
                </li>
                {/* <li><a class="icoLinkedin" title="youtube"><i class="fab fa-youtube" style="color: #237db2;"></i></a></li> */}
              </ul>
              <a href="https://vacationcoupon.com/terminos-condiciones.php">
                <p className="text-center">TÉRMINOS Y CONDICIONES</p>
              </a>
              <a href="https://bueno.com" style={{ fontSize: "14px" }}>
                Bueno incorporated DBA Vacation Coupon
              </a>
            </div>
          </div>
        </div>
        <div className="row copy">
          <div className="col-sm-12">
            <center>
              {countryCodeList.map((value, index) => {
                return (
                  <ReactCountryFlag
                    key={index}
                    countryCode={value}
                    svg
                    style={{
                      width: "2.6em",
                      height: "1.6em",
                      margin: "10px",
                    }}
                    title={value}
                    className="flagIcon"
                  />
                );
              })}
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 copy">
            <p className="text-center">
              © vacationcoupon.com 2021 Todos los derechos reservados Política
              de privacidad
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
